<template>
	<div class="w-100 map" :id="unique"></div>
</template>

<script>

import {Loader} from 'google-maps';
const options = {libraries: ["places"]};
const loader = new Loader('AIzaSyDBMPPcmN1nhyiM81J8VeyTPLRo7Okdcvs', options);

export default {
	name:"Map",
	props:{
		unique:{
            default: "thismap",
            type: String
        },
        store:{
            default:{},
            type: Object
        },
        city:{
            default: {lat: 20.97537, lng: -89.61696},
            type:Object
        },
        hover:{
            default: false,
            type:Boolean
        }
    },
    watch:{
        hover(){
            if(this.hover){
                this.animate();
            }else{
                this.unanimate();
            }
        }
    },
    beforeMount(){
        this.chargeMapApi();
    },
	methods:{
        animate(){
            try{
                this.marker.setAnimation(google.maps.Animation.BOUNCE);
                setTimeout(()=>{ this.marker.setAnimation(null); }, 500);
            }catch(err){}
        },
        unanimate(){
            try{
                this.marker.setAnimation(null);
            }catch(err){}
        },
		chargeMapApi(){
			loader.load().then(google => (
				this.loadMap(google)
			));
     	},
		locatePlace(req){
            let place = new google.maps.places.PlacesService(this.map);
            place.findPlaceFromQuery(req, (results, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && results) {
                    this.map.setCenter(results[0].geometry.location);
                    this.marker = new google.maps.Marker({
                        map: this.map,
                        position: results[0].geometry.location,
                    });

                }
            });
        },
		loadMap(google){
			
            this.map = new google.maps.Map(document.getElementById(this.unique), {
                    center: this.city,
                    zoom: this.zoom,
                    disableDefaultUI: true,
                    zoomControl: true,
            })
			this.request.query = this.store.street;
			this.locatePlace(this.request);
        }
	},
	data(){
		return{
			request: {
                query: null,
                fields: ["name", "geometry"],
            },
            map:{},
            zoom:13,
            marker:null,
		}
	}
}
</script>

<style coped>
    .map{
        height: 100%;
    }
</style>
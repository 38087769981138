<template>
    <div id="login" class="w-100 d-flex flex-wrap justify-content-center bg-3 align-items-start align-content-start flex-fill pt-lg-4">

        <loader v-if="this.loading"></loader>

        <img :src="phone.pass3 ? a2 : a3" class="col-4 col-md-3 col-xl-2 col-xxl-1 mt-4 mt-lg-5">

        <template v-if="confirmState">
            <div class="w-100 mt-4 mb-4 cl-1 fw-bold text-1 text-center">Te hemos enviado un código al:</div>

            <div class="w-100 mb-1 mt-2 cl-8 text-2 fw-bold text-center d-flex justify-content-center align-items-center align-content-center" style="letter-spacing:2px"><img :src="a4" class="me-2" style="height:35px">{{phone.data[0]+phone.data[1]+phone.data[2]}} {{phone.data[3]+phone.data[4]+phone.data[5]}} {{phone.data[6]+phone.data[7]+phone.data[8]+phone.data[9]}}</div>

            <div v-if="timer <= 0" class="w-100 mb-4 mt-1 cl-7 text-3 fw-bold text-center resetNumber" @click="resetNumber()">editar número</div>
            <div v-else class="w-100 mb-4 mt-1 cl-2 text-3 fw-bold text-center">Espere <span class="cl-8">{{Math.ceil(timer/1000)}}s</span> para otro intento</div>

            <div ref="codeContainer" class="mt-3 color-1 ts-6 fw-bold inputCodeContainer" :class="phone.pass2 && phone.pass3 ? '':'error'">
                <input ref="codePhone" @click="checkLastInput($event)" @paste="pasteCode($event)" @copy="disableCopy" @keyup="focusNextChrome($event, 0)" @keydown="focusNext($event, 0)" v-model="phone.code[0]" :type="(mobile == true) ? 'number' : 'text'" id="field-code-space1" name="field-code-space1" maxlength="1">
                <input ref="codePhone" @click="checkLastInput($event)" @paste="pasteCode($event)" @copy="disableCopy" @keyup="focusNextChrome($event, 1)" @keydown="focusNext($event, 1)" v-model="phone.code[1]" :type="(mobile == true) ? 'number' : 'text'" id="field-code-space2" name="field-code-space2" maxlength="1">
                <input ref="codePhone" @click="checkLastInput($event)" @paste="pasteCode($event)" @copy="disableCopy" @keyup="focusNextChrome($event, 2)" @keydown="focusNext($event, 2)" v-model="phone.code[2]" :type="(mobile == true) ? 'number' : 'text'" id="field-code-space3" name="field-code-space3" maxlength="1">
                <input ref="codePhone" @click="checkLastInput($event)" @paste="pasteCode($event)" @copy="disableCopy" @keyup="focusNextChrome($event, 3)" @keydown="focusNext($event, 3)" v-model="phone.code[3]" :type="(mobile == true) ? 'number' : 'text'" id="field-code-space4" name="field-code-space4" maxlength="1">
                <input ref="codePhone" @click="checkLastInput($event)" @paste="pasteCode($event)" @copy="disableCopy" @keyup="focusNextChrome($event, 4)" @keydown="focusNext($event, 4)" v-model="phone.code[4]" :type="(mobile == true) ? 'number' : 'text'" id="field-code-space5" name="field-code-space5" maxlength="1">
                <input ref="codePhone" @click="checkLastInput($event)" @paste="pasteCode($event)" @copy="disableCopy" @keyup="focusNextChrome($event, 5)" @keydown="focusNext($event, 5)" v-model="phone.code[5]" :type="(mobile == true) ? 'number' : 'text'" id="field-code-space6" name="field-code-space6" maxlength="1">
            </div>

            <div class="w-100 mb-1 mt-2 d-flex justify-content-center text-3 fw-bold cl-9">
                &nbsp;<span v-if="!phone.pass3">código incorrecto</span>
            </div>

            <button ref="matchCode" class="bg-1 cl-c1 text-4 col-6 col-md-5 col-lg-4 col-xl-3 col-xxl-2 mt-4 mt-lg-5 round-5 button-1 py-3 mb-5" @click="matchCode()">Confirmar</button>
        </template>

        <template v-else>
            <div class="w-100 text-center cl-1 fw-bold text-1 mt-3 mt-lg-4 mt-xl-5">Ingresa tu número de celular</div>

            <input ref="phoneNumber" @keyup.enter="linkPhone()" @paste="disablePaste" @copy="disableCopy" @keydown="onlyNumbers($event)" :class="phone.pass ? '':'error'" v-model="phone.data" type="text" class="round-5 cl-8 h-1 text-2 px-3 text-center numberInput col-8 col-md-6 col-lg-5 col-xl-4 col-xxl-3 mt-3 mt-lg-4 mt-xl-4" maxlength="10">

            <div class="w-100"></div>

            <div class="col-8 col-md-6 col-lg-5 col-xl-4 col-xxl-3 mt-3 text-start ps-4 cl-2 fw-bold text-3">Número celular de 10 dígitos</div>
            <div class="w-100"></div>

            <div class="w-100 mb-1 mt-1 d-flex justify-content-center text-2 fw-bold color-1">
                &nbsp;<span v-if="!phone.pass">{{phone.mess}}</span>
            </div>

            <button class="bg-1 cl-c1 text-4 col-6 col-md-5 col-lg-4 col-xl-3 col-xxl-2 mt-4 mt-lg-5 round-5 button-1 py-3 mb-5" @click="linkPhone()">Verificar y continuar</button>
        </template>
    </div>
</template>

<script>
import loader from "@/components/loader.vue";
export default {
    name: "Login",
    emits:['event'],
    components:{
        loader
    },
    props:{
        brand:{},
        con:null
    },
    mounted(){
        window.scrollTo(0, 0);
        this.mobile = this.isMobile();
        if(this.lastTimer && this.lastNumber){
            let date = (new Date).getTime();
            this.timer = Number(date) - Number(this.lastTimer);
            if(this.timer < 60000 && this.timer > 1000){
                this.timer = 60000 - this.timer;
                this.timer = Math.ceil(this.timer  / 1000) * 1000;
                this.confirmState = true;
                this.phone.data = this.lastNumber;
                this.countTime();
            }else{
                this.confirmState = false;
                this.phone.data = '';
            }
        }
        if(!this.confirmState){
            this.$refs.phoneNumber.focus()
        }
    },
    data() {
        return {
            a2: require("@/assets/images/a2.png"),
            a3: require("@/assets/images/a3.png"),
            a4: require("@/assets/images/a4.png"),
            confirmState:false,
            timer:0,
            loading:false,
            lastNumber:(localStorage.lastNumber) ? localStorage.lastNumber : false,
            lastTimer:(localStorage.lastTimer) ? localStorage.lastTimer : false,
            tokenAccess:(localStorage.keyPassHub) ? localStorage.keyPassHub : false,
            phone:{
                mess:"",
                data:"",
                pass:true,
                pass1:true,
                pass2:true,
                pass3:true,
                code:[null, null, null, null, null, null],
                lastCode:'',
            },
            mobile:false,
        };
    },
    computed:{
        completedCode(){
            return String(this.phone.code[0])+String(this.phone.code[1]||'')+String(this.phone.code[2]||'')+String(this.phone.code[3]||'')+String(this.phone.code[4]||'')+String(this.phone.code[5]||'');
        }
    },
    watch:{
        completedCode(){
            if(this.completedCode.length == 6 && this.completedCode != this.phone.lastCode){
                this.phone.lastCode = this.completedCode;
                this.$refs.matchCode.click();
            }
        }
    },
    methods: {
        pasteCode(e){
            e.preventDefault();
            e.stopPropagation();
            const pastedText = e.clipboardData.getData('text');
            const numbersOnly = pastedText.replace(/\D/g, '');
            this.phone.code = [null, null, null, null, null, null];
            this.$refs.codeContainer.childNodes.forEach((cube, index) => {
                if(index <= 5 && numbersOnly.length >= index){
                    this.phone.code[index] = numbersOnly[index]
                }
            })
        },
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        checkLastInput(e){
            this.phone.pass3 = true;
            for(let i=5; i >= 0; i--){
                if(e.target.parentNode.childNodes[i].value !== null && e.target.parentNode.childNodes[i].value !== undefined && e.target.parentNode.childNodes[i].value !== ''){
                    e.target.parentNode.childNodes[i].focus();
                    break;
                }
                if(i == 0){
                    e.target.parentNode.childNodes[0].focus();
                }
            }
        },
        onlyNumbers(e){
            let keyCode = (e.keyCode ? e.keyCode : e.which);
            if (!/\d/.test(e.key) && keyCode != 8){
                e.preventDefault();
            }
        },

        checkBrowser(){
            let ua = navigator.userAgent
            if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) && (/Chrome/i.test(ua))){
                return false
            }else{
                return true
            }
        },

        focusNextChrome(e, i){
            if(!this.checkBrowser()){
                let val = e.target.value;
                if (/\d/.test(val)){
                    this.phone.code[i] = String(e.target.value[e.target.value.length-1]);
                    e.target.value = e.target.value[e.target.value.length-1];
                    if(e.target.nextSibling !== undefined && e.target.nextSibling !== null){
                        e.target.nextSibling.focus();
                    }
                }else if(e.target.value == '' && i>0){
                    if(e.target.previousSibling !== undefined && e.target.previousSibling !== null){
                        e.target.previousSibling.focus();
                    }
                }else{
                    this.phone.code[i] = '';
                    e.target.value = '';
                }
            }
        },

        focusNext(e, i){
            if(this.checkBrowser()){
                this.phone.pass3 = true;
                let keyCode = (e.keyCode ? e.keyCode : e.which);
                if(e.ctrlKey && e.key == 'v'){
                    return true
                }
                e.preventDefault();
                if (/\d/.test(e.key)){
                    this.phone.code[i] = String(e.key)
                    if(e.target.nextSibling !== undefined && e.target.nextSibling !== null){
                        e.target.nextSibling.focus();
                    }
                }
                else if (keyCode == 8){
                    if((this.phone.code[i] == null || this.phone.code[i] == '') && i > 0){
                        e.target.previousSibling.focus();
                        this.phone.code[i-1] = null;
                    }else{
                        this.phone.code[i] = null;
                        if(e.target.previousSibling !== undefined && e.target.previousSibling !== null){
                            e.target.previousSibling.focus();
                        }
                    } 
                }
            }
        },
        disablePaste(event) {
            event.preventDefault();
        },
        disableCopy(event) {
            event.preventDefault();
        },
        countTime(){
            setTimeout(()=>{
                this.timer -= 1000;
                if(this.timer > 0){
                    this.countTime();
                }
            }, 1000)
        },

        resetNumber(){
            this.confirmState = false;
            this.phone.pass3 = true;
        },

        validateNow(stringInput, type) {
            let reg = null;
            let res = false;
            let string = String(stringInput);
            string = string.trim();
            switch (type) {
                case "phone":
                    reg = new RegExp(/^[0-9]{10,10}$/g);
                    break;
                case "code":
                    reg = new RegExp(/^[0-9]{6,6}$/g);
                    break;
            }
            res = reg.test(string);
            if (res && string != null) {
                return true;
            } else {
                return false;
            }
        },

        linkPhone(){
            if(this.validateNow(this.phone.data,"phone")){
                this.phone.code = [null, null, null, null, null, null, null, null];
                this.phone.mess = "";
                this.phone.pass = true;
                this.loading = true;
                this.con.getLogin({
                    "phone": this.phone.data,
                }).then((resp)=>{
                    if(resp.code == "200"){
                        this.confirmState = true;
                        localStorage.setItem('lastNumber', this.phone.data)
                        localStorage.setItem('lastTimer', (new Date).getTime())
                        this.timer = 60000;
                        this.countTime();
                        this.loading = false;
                        setTimeout(()=>{
                            document.getElementById('field-code-space1').click();
                        }, 500)
                        
                    }else{
                        this.phone.mess = resp.msj
                        this.phone.pass = false;
                        this.loading = false;
                    }
                }).catch((err)=>{
                    this.loading = false;
                    console.log(err)
                })
            }else{
                this.phone.pass = false;
            }
        },
        matchCode(){
            if(this.validateNow(this.phone.code.join(""),"code")){
                this.phone.pass2 = true;
                this.phone.pass3 = true;
                this.loading = true;
                this.con.matchCodeLogin({
                    "phone": this.phone.data,
                    "code": this.phone.code.join(""),
                }).then((resp)=>{
                    if(resp.code == 200){
                        localStorage.setItem('keyPassHub', resp.data);
                        this.timer = 0;
                        localStorage.removeItem('lastTimer');
                        this.loading = false;
                        this.$router.replace({ path: '/access'});
                    }else{
                        this.loading = false;
                        this.phone.pass3 = false;
                    }
                }).catch((err)=>{
                    this.loading = false;
                    this.phone.pass3 = false;
                })
            }else{
                this.phone.pass2 = false;
            }
        },
    },
};
</script>

<style scoped>
    .numberInput{
        border:1px solid #707070;
        letter-spacing: 3px;
    }
    .numberInput.error{
        color:#ff0000;
    }
    .numberInput:focus{
        color:var(--octagenary) !important;
    }
    .filler{
        width: 100%;
        height: 60px;
    }
    .error{
        border: 2px solid var(--nonagenary) !important;
    }
    .inputCodeContainer{
        height: 70px;
        width: 300px !important;
        position: relative;
        border-color: #707070;
        border-width: 1px;
        border-style: solid;
        border-radius: 14px;
        overflow: hidden;
        display:flex;
        justify-content:center;
        align-items:center;
        align-content:center;
        background-color: #fff;
    }
    .inputCodeContainer input{
        height: 50px;
        width: 30px;
        border:0;
        padding: 0 0 6px 0;
        border-radius: 0;
        border-bottom:3px solid rgba(119, 119, 119, 0.5);
        font-size: 36px;
        font-weight: bold;
        text-align: center;
        overflow: hidden;
        outline: none;
        background-color: transparent;
        color:var(--primary)
    }

    .inputCodeContainer input:not(:first-child){
        margin-left: 8px;
    }

    .resetNumber{
        cursor: pointer;
    }

    .resetNumber:hover{
        opacity: 0.5;
    }
</style>

<template lang="html">
    <!-- cargador visual -->
    <div class="cloader">
        <div class="loader"></div>
    </div>
</template>
<script>
export default {
    name:"loader",
}
</script>
<style lang="css">
    
</style>
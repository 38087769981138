<template lang="html">
    <router-view v-slot="{ Component, route }">
        <transition :name="route.meta.transition" :mode="route.meta.mode">
            <component :is="Component" :con="con" :keypass="keypass" :brand="brand" :patientList="patientList" :accountPropietary="accountPropietary"/>
        </transition>
    </router-view>
</template>
<script>



export default {
    name:"Access",
    props:{
        brand:{},
        con:null
    },
    beforeMount(){
        if(this.keypass){
            this.getMedicalAccounts();
        }
    },
    methods:{
        getMedicalAccounts(){
           this.con.getMedicalAccounts(this.keypass).then((resp)=>{
                if(resp.code == 200){
                    this.patientList = resp.data.patientList;
                    this.accountPropietary = resp.data.accountPropietary;
                }
            }).catch((err)=>{

            })
        },
    },
    data(){
        return{
            keypass: (localStorage.keyPassHub) ? localStorage.keyPassHub : false,
            accountPropietary:{},
            patientList:[]
        }
    },
}
</script>
<style lang="css">
</style>
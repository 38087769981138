import jwt_decode from "jwt-decode";
export default {
  name: "auth",
  components: {
    jwt_decode,
  },
  getJwt() {
    return jwt_decode(localStorage.getItem('keyPassHub'));
  },
  getIsLogged() {

    if(!localStorage.keyPassHub){
      return false
    }

    let jwt = null
    try {
      jwt = this.getJwt();
    } catch (e) {
      return false;
    }

    if (jwt == '' || jwt == undefined || jwt == null) {
      return false;
    }

    let now = new Date().getTime() / 1000

    if (jwt.iat > now) {
      return false;
    }

    if (jwt.exp < now) {
      return false;
    }

    return true;
  }
}

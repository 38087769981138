<template lang="html">
    <div class="head w-100 d-flex flex-wrap justify-content-between">
        <img :src="brand.logoHeader" class="logo align-self-end ms-4" @click="goToHome()">
        <div class="label cl-1 col-auto me-4 align-self-end pb-0 pb-sm-2">RESULTADOS</div>
        <div class="line bg-1 w-100 align-self-end"></div>
    </div>
</template>
<script>
export default {
    name:"headSpace",
    props:{
        brand:{},
    },
    data(){
        return{
            a1:require('@/assets/images/a1.png')
        }
    },
    methods:{
        goToHome(){
            if(this.$route.name != "medicalFamily" && this.$route.name != "home"){
                this.$router.push({ path: '/'});
            }
        }
    }
}
</script>
<style lang="css" scoped>
    .head{
        min-height: 140px;
        background-color: var(--head);
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
         -webkit-user-drag: none;
    }
    .logo{
        height: 50px;
        cursor: pointer;
    }
    .label{
        font-size: 22px;
    }
    .line{
        height: 33px;
    }
</style>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueMatomo from 'vue-matomo'
import VueSvgInlinePlugin from "vue-svg-inline-plugin";

import "vue-svg-inline-plugin/src/polyfills";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import '@/assets/css/global.css'

const app = createApp(App);

app.use(VueSvgInlinePlugin);
app.use(VueSvgInlinePlugin, {
	attributes: {
		data: [ "src" ],
		remove: [ "alt" ]
	}
});

app.use(router);
app.use(VueMatomo, {
    host: 'https://dashboard.efisense.net/analytics',
    siteId: 4,
});

app.mount('#app');
window._paq.push(['trackPageView']);

<template>
    <div class="inner" v-if="brand !== false">
        <head-space :brand="brand" v-if="this.$route.name != 'matchUUID'"></head-space>
        <router-view v-slot="{ Component, route }"  @event="filterEvent">
            <transition :name="route.meta.transition" :mode="route.meta.mode">
                <component :is="Component" :con="con" :brand="brand"/>
            </transition>
        </router-view>
        <foot-space :brand="brand" v-if="this.$route.name != 'matchUUID'"></foot-space>
    </div>
</template>

<script lang="javascript">
import  { request } from '@/assets/js/requests.js';
import headSpace from "@/components/headSpace.vue";
import footSpace from "@/components/footSpace.vue";
export default {
    name:"appGeneral",
    components: {
        headSpace,
        footSpace
    },
    mounted(){
        this.con = new request('https://videre-altabrisa.efisense.xyz/');
        this.con.getBrandConfig().then((response) => {
            if(response){
                if(response.data){
                    this.setCssVars(response.data).then((res)=>{
                        this.brand = response.data;
                    })
                }
            }
        })
    },
    data(){
        return{
            con:null,
            brand:false,
            observer:null,
            hidden:null,
        }
    },
    methods:{
        filterEvent(){},
        setCssVars(brand){
            return new Promise((resolve)=>{
                let rs = document.querySelector(':root');
                for(let i in brand.siteConfig.colors){
                     rs.style.setProperty('--'+i, brand.siteConfig.colors[i]);
                }
                document.getElementById("favicon").href = brand.favicon;
                resolve(true)
            })
        }
    }
}
</script>

<style lang="css" scoped>
    #app{
        overflow-y: overlay;
    }
    .inner{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        min-height: 100vh;
    }
    /* transiciones */

    .scale-enter-active,
    .scale-leave-active {
        transition: all 0.3s ease;
    }
    .scale-enter-from{
        opacity: 0;
        transform: scale(1);
    }
    .scale-leave-to {
        opacity: 0;
    }

    img{
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        -webkit-user-drag: none;
    }
    
</style>

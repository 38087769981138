import { createRouter, createWebHistory } from 'vue-router'
import auth from '../assets/js/auth.js'
import login from '../views/Login.vue'
import access from '../views/Access.vue'
import magicAuth from '../views/magicAuth.vue'
import NotFound from '../views/404.vue'

const DEFAULT_TITLE = 'Resultados';

const routes = [
    {
        path: '/404', name: 'NotFound', component: NotFound,
        meta: { transition: 'scale', mode: "out-in", requiresAuth: false, title: "404" },
    },
    {
        path: '/:catchAll(.*)', redirect:'404',
        meta: { transition: 'scale', mode: "out-in", requiresAuth: false, title: "404" },
    },
    {
        path: '/',
        name: 'home',
        component: login,
        props: true,
        meta: { transition: 'scale', mode: "out-in", requiresAuth: false, title: "Ingresa" },
    },
    {
        path: '/auth',
        name: 'magicAuth',
        component: magicAuth,
        props: true,
        meta: { transition: 'scale', mode: "out-in", requiresAuth: false, title: "byPass" },
        children: [
            {
                path: "",
                name: "redirect",
                component: () => import('../components/redirect.vue'),
                props: true,
                meta: { transition: 'scale', mode: "out-in", requiresAuth: false, title: "redirect" },
            },
            {
                path: ":magic",
                name: "matchUUID",
                component: () => import('../components/matchMagicLinear.vue'),
                props: true,
                meta: { transition: 'scale', mode: "out-in", requiresAuth: false, title: "Credenciales" },
            },
        ]
    },
    {
        path: '/access',
        name: 'access',
        component: access,
        props: true,
        meta: { transition: 'scale', mode: "out-in", requiresAuth: true, title: "Sessión" },
        children: [
            {
                path: "",
                name: "medicalFamily",
                component: () => import('../components/userManager.vue'),
                props: true,
                meta: { transition: 'scale', mode: "out-in", requiresAuth: true, title: "Control" },
            },
            {
                path: ":emr",
                name: "current",
                component: () => import('../components/resultsList.vue'),
                props: true,
                meta: { transition: 'scale', mode: "out-in", requiresAuth: true, title: "Lista de Resultados" },
            },
        ]
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from) => {
    if (to.meta.requiresAuth && (!auth.getIsLogged() )) {
        return {
            path: '/',
        }
    }
    else if (to.name === "home" && (auth.getIsLogged()) ) {
        return {
            path: '/access',
        }
    }else if (to.name === "magicAuth" || to.name === "matchUUID" ) {
        localStorage.removeItem('keyPassHub');
    }
})

router.afterEach((to, from) => {
    document.title = to.meta.title || DEFAULT_TITLE;
});


export default router

<template lang="html">
    <router-view v-slot="{ Component, route }">
        <transition :name="route.meta.transition" :mode="route.meta.mode">
            <component :is="Component" :con="con" :brand="brand"/>
        </transition>
    </router-view>
</template>
<script>
export default {
    name:"magicLogin",
    props:{
        brand:{},
        con:null,
    },
    mounted(){

    },
    data(){
        return{}
    }
}
</script>
<style lang="css">
    
</style>
import axios from "axios";

class request {

	apiUrl = "";
	authT0ken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJFZmlzZW5zZSIsImF1ZCI6ImVmaXNlbnNlLmNvbSIsInN5c3RlbSI6InBvcnRhbCIsImlhdCI6MTY5NDAyNzUxMywiZXhwIjoxNzg4NjM1NTEzLCJzdWIiOiIxNTMiLCJpZERvY3RvciI6IjE1MyIsImlkVXNlciI6IjE1MyIsImVtYWlsIjoiIiwicm9sZSI6ImFkbWluIiwibmFtZSI6IkVmaXNlbnNlIiwibGFzdG5hbWUiOiJTb3BvcnRlIiwiaWRMaWNlbnNlIjoiNyIsInVyaSI6IiIsInRva2VuIjoiVWtzeGJDTWhWV0pYZVRZMFpqUmxOall4TVdKbVpHUTFMak14TURVMU9EUTEiLCJsaW1pdFZlcnNpb24iOnsib25pY29FTVIiOiI1LjEuMyJ9LCJzdG9yZXMiOnsiNyI6IjcifSwic3RvcmUiOiI3In0.Pyo4-G3jBQM1DWFkbDMNcjHTrZ1Z-Gn7u9C_rTJkW8g";
	settings = {}

	defSettings = {
		"url": "",
		"method": "GET",
		"timeout": 0,
	}

	constructor(api) {
		this.apiUrl = api;
	}

	jsonRequest = (req, parameter = null) => {

		this.settings = {};

		switch (req) {

			case "getBrandConfig":
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "apiOnicoHub/Brand";
				this.settings["headers"] = { /*"Referer": camps*/};
				break;

			case "getMagicToken":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "apiOnicoHub/Login/byToken/"+parameter;
				break;

			case "getLogin":
				this.settings = {...this.defSettings};
				this.settings["method"] = "POST";
				this.settings["url"] = this.apiUrl + "apiOnicoHub/Login/verification";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
				}
				this.settings["data"] = JSON.stringify(parameter);
				break;

			case "matchCodeLogin":
				this.settings = {...this.defSettings};
				this.settings["method"] = "POST";
				this.settings["url"] = this.apiUrl + "apiOnicoHub/Login/checkVerification";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					Authorization:
						"Bearer " + this.authT0ken,
				}
				this.settings["data"] = JSON.stringify(parameter);
				break;

			case "getMedicalFam":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "apiOnicoHub/Record/Patient";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					Authorization:
						"Bearer " + parameter[0],
				}
				this.settings["data"] = JSON.stringify(parameter[1]);
				break;
			case "getMedicalAccounts":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "apiOnicoHub/Record/Patient";
				this.settings["headers"] = { 
					Authorization:
						"Bearer " + parameter,
				}
				break;
			
			case "getPatientFolders":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "apiOnicoHub/Record/Pdf/byIdPatient/"+parameter[0];
				this.settings["headers"] = { 
					Authorization:
						"Bearer " + parameter[1],
				}
				break;

			case "getPdfRender":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "apiOnicoHub/Record/Pdf/"+parameter[0];
				this.settings['responseType'] = 'arraybuffer';
				this.settings["headers"] = { 
					Authorization:
						"Bearer " + parameter[1],
				}
				break;

			default:
				return false
		}
		return this.settings
	}

	getBrandConfig = (data = {}) => {
		let json = this.jsonRequest("getBrandConfig", data);
		return this.petition(json, false)
	}

	getMagicToken = (data = {}) => {
		let json = this.jsonRequest("getMagicToken", data);
		return this.petition(json, false)
	}

	getLogin = (data = {}) => {
		let json = this.jsonRequest("getLogin", data);
		return this.petition(json, false)
	}

    matchCodeLogin = (data = {}) => {
		let json = this.jsonRequest("matchCodeLogin", data);
		return this.petition(json, false)
	}

	getMedicalAccounts = (data) => {
		let json = this.jsonRequest("getMedicalAccounts", data);
		return this.petition(json, false)
	}

	getPatientFolders = (data) => {
		let json = this.jsonRequest("getPatientFolders", data);
		return this.petition(json, false)
	}

	getPdfRender = (data) => {
		let json = this.jsonRequest("getPdfRender", data);
		return json
	}

	petition = async (json, watch=true) => {
		return new Promise(function(resolve, reject) {
			if(json){
				axios(json)
				.then(function (response) {
					resolve(response.data);
				})
				.catch(function (error) {
					if(watch){
						console.log(error);
					}
					reject(error);
				})
			}else{
				let response = {data:false,code:500}
				resolve(response);
			}
		});
	}
}


export { request };
<template>
	<div class="row w-100 p-0 m-0 px-0 px-md-5">

		<div class="w-100 p-0">
			<div class="d-inline-block mb-3 mt-5 mb-4 px-4 py-2 ms-4 text-3 cl-c1 bg-1">Ubicación y contacto&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
		</div>

		<span :id="ids" @mouseenter="inm($event)" @mouseleave="outm($event)" v-for="(store, ids) in brand.stores" :key="ids" class="w-100 d-flex flex-wrap p-0">

			<div class="w-100 d-flex flex-md-nowrap flex-wrap justify-content-center align-content-stretch mb-2 px-5">
				<div class="col-12 col-md-7 d-flex justify-content-center pe-0 pe-md-4 mb-2 mb-md-0">
					<div class="mb-3 mb-md-0 h-100 d-flex flex-wrap col-12 bg-5 px-0 px-md-4 py-4">

						<span class="col-12 d-flex justify-content-center mb-4 fs-4 cl-1">{{store.name}}</span>

						<span class="col-12 d-flex justify-content-center justify-content-md-start fs-5 cl-1">Dirección</span>
						<div class="col-12 d-flex justify-content-center text-center text-md-start justify-content-md-start px-md-5 px-2 fs-6 my-2">{{store.street}}</div>

						<span class="col-12 d-flex justify-content-center justify-content-md-start fs-5 cl-1">Email</span>
						<div class="col-12 d-flex justify-content-center justify-content-md-start fs-6 px-md-5 px-2 my-2">{{store.email}}</div>

						<span class="col-12 d-flex justify-content-center justify-content-md-start fs-5 cl-1">Telefonos</span>
						<div class="col-4 col-md-auto d-flex justify-content-center justify-content-md-start my-2 px-md-5 px-2 fs-6" v-for="phone in store.phone" :key="phone">{{phone}}</div>
					</div>
				</div>
				<div class="col-12 col-md-5">
					<div class="h-100 border border-2 border-quinary-custom overflow-hidden" style="min-height:200px">
						<map-google :hover="hover[ids]" :store="store" :unique="`map-${ids}`"></map-google>
					</div>
				</div>
			</div>


		</span>
	</div>
</template>

<script>

import MapGoogle from "@/components/map.vue";

export default {
	name:"location",
	components:{
		MapGoogle,
	},
	props:{
		brand:{
			default:{},
			type:Object
		},	
    },
	beforeMount(){
		this.hoverArray();
	},
	methods:{
		inm(e){	
			if(!this.hover[e.target.id]){
				this.hover[e.target.id] = true;
			}
		},
		outm(e){
			this.hover[e.target.id] = false;
		},
		hoverArray(){
			for(let i in this.brand.stores){
				this.hover.push(false)
			}
		}
	},
	data(){
		return{
			hover:[]
		}
	}
}
</script>

<style scoped>
@media (min-width: 0px) and (max-width: 750.97px) {
	.fs-6{
		font-size: 13px !important;
	}
	.fs-5{
		font-size: 16px !important;
	}
	.fs-4{
		font-size: 19px !important;
	}
}
</style>